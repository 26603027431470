import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet'
import Loading from '../../components/loading'
import Navbar2 from '../../layouts/navbar2'
import { FiberManualRecord } from '@mui/icons-material'

const PrivacyPolicy = () => {
  return (
    <>
        <Helmet>
        <title>
        Privacy Policy
        </title>
        <meta name="description" content="How it Works - TEAS Gurus" />
      </Helmet>
    <div className="w-[100%] justify-center flex md:mt-20 mt-3">
      <div className="md:w-[90%] w-[95%]">
        <Suspense fallback={<div><Loading/></div>}>
          <Navbar2/>
        </Suspense>
    </div>
    </div>

    <div className="w-[100%] justify-center flex md:mt-20 mt-3">

        <div className='md:w-[70%] w-[90%]'>
            <div></div>
            <div className='text-[17px]'>The following privacy notice explains our policy regarding the personal information we collect about you in the process of your cooperation with Take My Online Course website. It applies to the information we collect about:</div>

            <div className='mt-[40px]'>
              <div className='flex items-center gap-2 text-[17px]'><FiberManualRecord fontSize='15' sx={{color:'yellow'}}/>Visitors of our website</div>
              <div className='flex items-center gap-2 mt-2 text-[17px]'><FiberManualRecord fontSize='15' sx={{color:'blue'}}/>People who use our services</div>
            </div>


            <div className='mt-[50px]'>
               <div className='text-[24px] font-[800]'>Information we collect as you webrowse our website</div>
               <div className='text-[17px] mt-5'>TOur web server automatically collects standard information, including your browser type, operating system and access time. We collect this information in a way, which does not identify anyone. We do not make any attempt to find out the identities of those visiting our website.</div>

               <div className='text-[17px] mt-5'>This information is used only to improve the content of our web pages and to customize it and/or the layout of our pages. We never sell, rent or share any of the information we collect by this method (except anonymous aggregate information) with any third parties.</div>

               <div className='text-[17px] mt-5'>We use Google Analytics to help analyze use of our website. This analytical tool uses ‘cookies’;, which are text files placed on your computer, to collect standard Internet log information and visitor behavior information in an anonymous form. The data generated by the cookie about your use of the website is transmitted to Google. This information is then used to evaluate visitors’ use of the website and to compile statistical reports on website’s activity. To find out more about cookies, including how to control and delete them, visit </div>
            </div>


            <div className='mt-[50px]'>
               <div className='text-[24px] font-[800]'>Information we collect when you use our services</div>
               <div className='text-[17px] mt-5'>When you start your cooperation with us, you will be asked to provide personal information in order to perform payments for our services, control the process of your order fulfillment and receive the completed piece of writing. We may use any contact information (emails and phone numbers) submitted to this website. We have to hold the details of the people who have requested the service in order to provide it. However, we only use these details to provide the service the person has requested and for other closely related purposes. We will use your email address to send you notifications considering the most important stages of order fulfillment, such as clarification of any issues, unread messages and order completion. Your email and telephone number may also be used for promotional and marketing purposes, to notify you of special offers and discounts, etc.</div>

               <div className='text-[17px] mt-5'>This information will be used only by our Customer Support Representatives and will not on any condition be disclosed to any third party; it is never sold, rented or shared.</div>

               <div className='text-[17px] mt-5'>All the information in the order, inquiry pages and personal control panel is processed with the use of the SSL-certificate, which guarantees complete confidentiality and safety of all the transactions. </div>
            </div>


            <div className='mt-[50px]'>
               <div className='text-[24px] font-[800]'>Complaints or queries</div>
               <div className='text-[17px] mt-5'>We try to meet the highest standards when collecting and using personal information. For this reason, we take any complaints we receive about this very seriously. We encourage people to bring it to our attention if they think that our collection or use of information is unfair, misleading or inappropriate. We would also welcome any suggestions for improving our procedures.</div>

               <div className='text-[17px] mt-5'>This privacy notice was drafted with brevity and clarity in mind. It does not provide exhaustive details of all the aspects of our collection and use of personal information. However, we are happy to provide any additional information or explanation needed. Any requests for this should be sent to our email.</div>

            </div>


            <div className='mt-[50px]'>
               <div className='text-[24px] font-[800]'>Access to personal information</div>
               <div className='text-[17px] mt-5'>We try to be as open as we can in terms of giving people access to their personal information. Individuals can find out if we hold any personal information about them by making a “subject access request” under the Data Protection Act 1998. If we do hold information about you, we will:</div>

               <div className='mt-[20px]'>
              <div className='flex items-center gap-2 text-[17px]'><FiberManualRecord fontSize='15' sx={{color:'yellow'}}/>Give you a description of it;</div>
              <div className='flex items-center gap-2 mt-2 text-[17px]'><FiberManualRecord fontSize='15' sx={{color:'blue'}}/>
              Tell you why we are holding it;</div>

              <div className='flex items-center gap-2 mt-2 text-[17px]'><FiberManualRecord fontSize='15' sx={{color:'orange'}}/>
              Tell you to whom it could be disclosed;</div>

              <div className='flex items-center gap-2 mt-2 text-[17px]'><FiberManualRecord fontSize='15' sx={{color:'green'}}/>
              Let you have a copy of the information in an intelligible form.</div>
            </div>

               <div className='text-[17px] mt-5'>To make a request to us for any personal information we may need you to put the request in writing addressing it to our Customer Support Representatives via email. If you agree, we will try to deal with your request informally, for example by providing you with the specific information you need over the telephone. If we do hold information about you, you can ask us to correct any * mistakes by, once again, contacting Customer Support Representatives.</div>

            </div>


            <div className='my-[50px]'>
               <div className='text-[24px] font-[800]'>Links Disclaimer</div>
               <div className='text-[17px] mt-5'>In order to provide visitors with certain information, this website provides links to local, state and federal government agencies, and websites of other organizations. A link does not constitute an endorsement of the content, viewpoint, accuracy, opinions, policies, products, services, or accessibility of that website. Once you link to another site from this website, including one maintained by the State, you are subject to the terms and conditions of that website, including, but not limited to, its privacy policy.</div>

              

            </div>
        </div>
        </div>

    </>
  )
}

export default PrivacyPolicy
