export const ImageData = [
    {
       id:1,
       src:"/home/1.gif"
    },
    {
        id:2,
        src:"/home/3.gif"
     },
     {
        id:3,
        src:"/home/4.gif"
     },
     {
        id:4,
        src:"/home/5.gif"
     }
]